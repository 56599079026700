var axios = require("axios").default;
const baseUrl = `https://api.stage.ebsr.in/`;
var localData = (JSON.parse(localStorage.getItem('token')) === null) ? window.location.href = '/' : JSON.parse(localStorage.getItem('token'));
var token = localData.token
export default class donor {
    async donorList() { 
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donorNotification/fetch`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };


    async getMasterSheet(donor_id) {
        try {
            const response = await axios.request({
                method: "POST",
                url: `${baseUrl}reports/master_report/`+donor_id,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data
        }catch (error) {
            console.error(error)
            return false
        }
    }
    
    async reAssignDonorList() {
        try {
            const response = await axios.request({
                method: "POST",
                url: `${baseUrl}donorNotification/re_assign_donor_info`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    }; 

    async donorNotificationDetail(data) {  
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donorNotification/single-notify/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async updateDonorInformation(data) {
        try {
            const response = await axios.post(`${baseUrl}donorNotification/add_donor_information`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };
    
    async updateDonorNotification(data) {
        try {
            const response = await axios.post(`${baseUrl}donorNotification/add_donor_notification`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };

    async donorSearchList(data) {
        try {
            const response = await axios.post(`${baseUrl}donorNotification/search`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };

    async reAssignDonorSearchList(data) {
        try {
            const response = await axios.post(`${baseUrl}donorNotification/re_assign_donor_info`, data, {
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })

            return response.data;
        } catch (error) {
            console.error(error);
            return false;
        }

    };

    async FormAData(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donorNotification/form-a/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
               
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async FormBData(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donorCase/form-b/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
               
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async FormCData(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donor_medical/form-c/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
               
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };

    async retrieval_form(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}eyedonormedical/fetch/singal/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async SingalDonor(data) {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donorNotification/single-information/${data}`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
    async getEDCT() {
        try {
            const response = await axios.request({
                method: "GET",
                url: `${baseUrl}donorNotification/fetch/form-a`,
                headers: {
                    'Content-type': 'application/json',
                    Authorization: `Bearer ${token}`,
                },
            })
            return response.data
        } catch (error) {
            console.error(error)
            return false
        }
    };
}